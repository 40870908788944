import React from "react"
// import { useReduceMotion } from "react-reduce-motion";
import { useScrollAnimation } from "../components/useScrollAnimation";
import { motion, useTransform } from "framer-motion";
import Layout from '../components/Layout'
import Seo from '../components/seo'
import BannerMp4 from '../videos/about.mp4'
import BannerWebm from '../videos/about.webm'
import BannerOgv from '../videos/about.ogv'
import BannerCover from '../videos/video_cover_what.jpg'
import { StaticImage } from "gatsby-plugin-image";
import Testimonial from '../components/Testimonial'
import TechLogos from '../components/TechLogos'
import Icon from '@mdi/react'
import { mdiCloudCheckOutline, mdiDatabase, mdiCreation, mdiMonitorCellphone, mdiCogRefreshOutline, mdiCodeJson } from '@mdi/js'

export default function What() {

    // const prefersReducedMotion = useReduceMotion();
    const prefersReducedMotion = false; //react-reduce-motion fails at build time due to window

    const [bannerRef, bannerScroll] = useScrollAnimation();
    const bannerScale = useTransform(bannerScroll, [0, 0.5, 1], prefersReducedMotion ? [1, 1, 1] : [1, 1, 1.2]);

    const [section3Ref, section3Scroll] = useScrollAnimation();
    const section3Img1Scale = useTransform(section3Scroll, [0, 1], prefersReducedMotion ? [1.2, 1] : [1.4, 1]);
    const section3Img2Scale = useTransform(section3Scroll, [0, 1], prefersReducedMotion ? [1.2, 1] : [1.4, 1]);

    const [section7Ref, section7Scroll] = useScrollAnimation();
    const section7Img1Scale = useTransform(section7Scroll, [0, 1], prefersReducedMotion ? [1.2, 1] : [1.4, 1]);
    const section7Img2Scale = useTransform(section7Scroll, [0, 1], prefersReducedMotion ? [1.2, 1] : [1.4, 1]);
    const section7Img3Scale = useTransform(section7Scroll, [0, 1], prefersReducedMotion ? [1.2, 1] : [1.4, 1]);


    return (
        <Layout>

            <Seo
                title='Custom cloud software, apps and AI technology | Webfox - What we do'
                description='We help progressive Kiwi organisations innovate, realise untapped commercial potential and set new standards for digital engagement to give their business an edge.'
            />

            {/* section1 */}
            <div ref={bannerRef}>
                <section className="layer intro intro-default dark">
                    <motion.video animate={{ scale: bannerScale.get() }} id="background-video" autoPlay loop muted playsInline poster={BannerCover}>
                        <source src={BannerWebm} type='video/webm' />
                        <source src={BannerMp4} type='video/mp4' />
                        <source src={BannerOgv} type='video/ogg' />
                    </motion.video>

                    <div className="inner">
                        <div className="copy">
                            <h1>What We Do</h1>
                        </div>
                    </div>
                </section>
            </div>

            {/* section 2 */}
            <section className="layer double-text transparent">
                <div className="inner">
                    <div className="grid col-2 align-top">
                        <div className="col">
                            <h2>Redefine the way you do business with smart digital tools and bespoke software</h2>
                        </div>
                        <div className="col">
                            <p>We help progressive organisations innovate, realise untapped commercial potential and set new standards for digital solutions to give their business an edge.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* section 3 */}
            <section className="layer staggered-images transparent">
                <div ref={section3Ref} className="inner">
                    <div className="grid col-2">
                        <div className="col">
                            <div className="background-zoom-image">
                                <motion.div animate={{ scale: section3Img1Scale.get() }}>
                                    <StaticImage
                                        src="../images/alexis-walking-out-of-office.jpg"
                                        alt="Webfox office at the Tech Collective"
                                        style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
                                    />
                                </motion.div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="background-zoom-image">

                                <motion.div animate={{ scale: section3Img2Scale.get() }}>
                                    <StaticImage
                                        src="../images/office-superheros.jpg"
                                        alt="Developer workstation"
                                        style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* section 4 */}
            <section className="layer services transparent">
                <div className="inner">
                    <div className="grid col-3">
                        <div className="col">
                            <span className="icon">
                            <Icon path={mdiCloudCheckOutline} size={1.3} />
                            </span>
                            <h3>Custom cloud-based software </h3>
                            <p>Get built-for-your-business solutions that make it easier for your organisation to achieve strategic objectives, streamline workflow and support existing processes.</p>
                        </div>
                        <div className="col">
                            <span className="icon">
                            <Icon path={mdiCreation} size={1.3} />
                            </span>
                            <h3>AI &amp; machine learning technology</h3>
                            <p>We’ll put your platforms to work by leveraging advanced AI and machine learning technologies to automate tasks, optimise productivity and leverage data.</p>
                        </div>
                        <div className="col">
                            <span className="icon">
                            <Icon path={mdiDatabase} size={1.3} />
                            </span>
                            <h3>Data integration</h3>
                            <p>Make your systems work smarter, seamlessly. We help you reduce manual data entry, double handling and siloed systems with automated, connected workflows that scale as your business does.</p>
                        </div>
                        <div className="col">
                            <span className="icon">
                            <Icon path={mdiMonitorCellphone} size={1.3} />
                            </span>
                            <h3>Progressive web applications</h3>
                            <p>Create one platform that works anywhere online. We develop secure, responsive web apps that work wherever you need to connect with your customers online.</p>
                        </div>
                        <div className="col">
                            <span className="icon">
                            <Icon path={mdiCogRefreshOutline} size={1.3} />
                            </span>
                            <h3>Systems automation</h3>
                            <p>Streamline inefficiencies with smart technology that frees up time for your people to focus on productive, high-value tasks that truly drive your business forward.</p>
                        </div>
                        <div className="col">
                            <span className="icon">
                            <Icon path={mdiCodeJson} size={1.3} />
                            </span>
                            <h3>Website development</h3>
                            <p>We build highly functional websites that give you a digital presence with an edge - one that truly works hard for your business online.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* section 5 */}
            <Testimonial />


            {/* section 6 */}
            <section className="layer double-text transparent">
                <div className="inner">
                    <div className="grid col-2 align-top">
                        <div className="col">
                            <h2>Our approach - We identify opportunities</h2>
                        </div>
                        <div className="col">
                            <p>You might have a vision, or you might not know yet what you need. Either way, we help you discover what’s possible, then we make it happen. With big picture thinking, we consider solutions that solve problems for your business and create opportunities to cut a new course in your industry.</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* section 7 */}
            <section className="layer alternating transparent">
                <div ref={section7Ref} className="inner">
                    <div className="grid col-2">
                        <div className="col">
                            <div className="background-zoom-image">

                                <motion.div animate={{ scale: section7Img1Scale.get() }}>
                                    <StaticImage
                                        src="../images/geek.jpg"
                                        alt="Webfox are developers who get it done"
                                        width={960}
                                        height={960}
                                        style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
                                    />
                                </motion.div>
                            </div>
                        </div>
                        <div className="col">
                            <h2>We’re geeks who get it</h2>
                            <p>We’re developers and technologists who understand real-world commercial challenges. Because you work directly with us, we translate business goals straight into code that actually delivers on human-centred outcomes. </p>
                            <p>With an evolving technology stack, we build with whatever is best for your needs. </p>
                        </div>
                    </div>
                    <div className="grid col-2">
                        <div className="col">
                            <div className="background-zoom-image">

                                <motion.div animate={{ scale: section7Img2Scale.get() }}>
                                    <StaticImage
                                        src="../images/security-code.jpg"
                                        alt="Webfox takes security seriously"
                                        width={960}
                                        height={960}
                                        style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
                                    />
                                </motion.div>
                            </div>
                        </div>
                        <div className="col">
                            <h2>We take security seriously</h2>
                            <p>We’re proactive in our approach to information security. We use technology that is robust and reliable, have a deep understanding of non-disclosure and commercial sensitivity and undertake all measures to ensure your data is protected.</p>
                        </div>
                    </div>
                    <div className="grid col-2">
                        <div className="col">
                            <div className="background-zoom-image">
                                <motion.div animate={{ scale: section7Img3Scale.get() }}>
                                    <StaticImage
                                        src="../images/intouch2.jpg"
                                        alt="Webfox keeps in touch with clients"
                                        width={960}
                                        height={960}
                                        style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
                                    />
                                </motion.div>
                            </div>
                        </div>
                        <div className="col">
                            <h2>We keep in touch</h2>
                            <p>Our agile process and proactive project management keeps you in the loop at every stage of the project.</p>
                            <p>Once your app is out in the wild, we don’t leave you to it. Our support is critical to the longevity of your product, with on-going monitoring, testing, updates and upgrades for continual improvement.</p>
                        </div>
                    </div>
                </div>
            </section>

            <TechLogos />

        </Layout>
    )
}
