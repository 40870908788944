import React from 'react'
import { useScrollAnimation } from "../components/useScrollAnimation";
import { motion, useTransform } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";


export default function Testimonials() {


    // const prefersReducedMotion = useReduceMotion();
    const prefersReducedMotion = false; //react-reduce-motion fails at build time due to window

    const [ref, sectionScroll] = useScrollAnimation();
    const imgScale = useTransform(sectionScroll, [0, 1], prefersReducedMotion ? [1.2, 1] : [1.4, 1]);

    return (


        <section id="testimonial" className="layer transparent quote-wrap  dark">
            <div ref={ref} className="inner">
                <div className="quote grid black-bg">
                    <div className="background-zoom-image">

                        <motion.div animate={{ scale: imgScale.get() }} className="imgWrapper">
                            <StaticImage
                                src="../images/richard-martin.jpg"
                                alt="Webfox testimonial by Richard Martin"
                                style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
                            />
                        </motion.div>
                    </div>
                    <div className="quote-text">
                        <p className="quoteBody">“The team at Webfox are outstanding. They listen, they understand and they execute. The greater the challenge, the better they respond. I have no hesitation in recommending Webfox for your digital project.”</p>
                        <p className="quoteAuthor">Richard Martin - Funeral Stream</p>
                    </div>

                </div>
            </div>
        </section>
    )
}